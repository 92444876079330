import React from "react";
import Button from "../button/Button";
import { Link } from "react-router-dom";  // Import Link from react-router-dom

const IntroEmpresa = () => {
    return(
        <section className="intro-empresa-section gridrow">
            <div className="intro-empresa-texto col-12 col-t-12 col-d-8">
                <h2>Sobre Nós</h2>
                <p className="col-12">
                    No Stand, acreditamos que a compra de um automóvel é mais do que uma simples transação – é o início de uma nova jornada. Com anos de experiência no mercado automóvel, somos dedicados a oferecer veículos de alta qualidade, acompanhados por um serviço excecional. A nossa missão é garantir a satisfação de cada cliente, proporcionando uma experiência de compra transparente, segura e personalizada. Se procuras confiança, compromisso e uma equipa apaixonada por automóveis, estás no sítio certo.
                </p>
                <Link to="/contactos"><Button name={'Onde Estamos'}/></Link>
            </div>
            <div className="intro-empresa-items col-12">
                <div className="intro-empresa-texto col-12 col-t-12 col-d-6">
                    <h3>Qualidade</h3>
                    <p className="col-12">
                        A qualidade é a nossa prioridade. Cada veículo no nosso Stand passa por rigorosos testes de inspeção para assegurar que corresponde aos mais altos padrões do mercado. Estamos comprometidos em oferecer apenas o melhor, para que possas conduzir com total confiança.
                    </p>
                </div>
                <div className="intro-empresa-texto col-12 col-t-12 col-d-6">
                    <h3>Competência</h3>
                    <p className="col-12">
                        A nossa equipa é composta por profissionais altamente qualificados e experientes no setor automóvel. Desde consultores de vendas a técnicos especializados, todos estão prontos para te ajudar a encontrar o veículo perfeito, respondendo a todas as tuas questões com conhecimento e simpatia.
                    </p>
                </div>
                <div className="intro-empresa-texto col-12 col-t-12 col-d-6">
                    <h3>Pós Venda</h3>
                    <p className="col-12">
                        No Stand, o nosso compromisso não termina com a venda. Oferecemos um serviço de pós-venda abrangente, incluindo assistência técnica e manutenção, para garantir que o teu automóvel se mantém em excelente condição durante muitos anos.
                    </p>
                </div>
                <div className="intro-empresa-texto col-12 col-t-12 col-d-6">
                    <h3>Garantia</h3>
                    <p className="col-12">
                        Todos os nossos veículos vêm com garantia completa, dando-te a tranquilidade que mereces. Conduz com confiança, sabendo que estás coberto por uma garantia que protege o teu investimento.
                    </p>
                </div>
            </div>
        </section>
    );
}

export default IntroEmpresa;