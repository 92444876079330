import React, { useState } from 'react';
import '../style.css';
import Menu from '../components/menu/Menu';
import Footer from '../components/footer/Footer';
import ToTop from '../components/toTop/ToTop';
import Cover from '../components/cover/Cover';
import ProdutosDestaque from '../components/produtoDestaque/ProdutoDestaque';
import UltimasEntradas from '../components/ultimasEntradas/UltimasEntradas';
import NumerosEmpresa from '../components/numerosEmpresa/NumerosEmpresa';
import ContactoProduto from '../components/contactoProduto/ContactoProduto';
import Loading from '../components/loadingScreen/LoadingScreen'; // Import Loading component

function Home() {
    const [loading, setLoading] = useState(false);

    return (
        <>
            <Menu />
            <Cover />
            {loading && <Loading />} {/* Show Loading component if loading */}
            <ProdutosDestaque setLoading={setLoading} />
            <UltimasEntradas setLoading={setLoading} />
            <NumerosEmpresa />
            <ContactoProduto />
            <ToTop />
            <Footer />
        </>
    );
}

export default Home;