import React, { useState, useEffect } from 'react';
import '../style.css';
import axios from 'axios';
import Menu from '../components/menu/Menu';
import PageTitle from '../components/pageTitle/PageTitle';
import ToTop from '../components/toTop/ToTop';
import Footer from '../components/footer/Footer';
import ListaProdutos from '../components/listaProdutos/ListaProdutos';
import FiltroProdutos from '../components/filtroProdutos/FiltroProdutos';
import CoverImage from '../assets/carros_cover.jpg';
import ContactoProduto from '../components/contactoProduto/ContactoProduto';

function Produtos({ setLoading }) {
  const [produtos, setProdutos] = useState([]);
  const [error, setError] = useState(null);
  const [produtosFiltrados, setProdutosFiltrados] = useState([]);

  const baseUrl = process.env.REACT_APP_API_URL;
  const CACHE_KEY = 'produtosCache';
  const CACHE_VALIDITY_PERIOD = 60 * 60 * 1000; // 1 hour

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);  // Trigger the loading state
      const cachedData = JSON.parse(localStorage.getItem(CACHE_KEY));

      if (cachedData && (Date.now() - cachedData.timestamp < CACHE_VALIDITY_PERIOD)) {
        setProdutos(cachedData.products);
        setProdutosFiltrados(cachedData.products);
        setLoading(false);
        return;
      }

      try {
        const produtosResponse = await axios.get(`${baseUrl}/products`, {
          headers: {
            Authorization: `Basic ${btoa(`${process.env.REACT_APP_WC_CONSUMER_KEY}:${process.env.REACT_APP_WC_CONSUMER_SECRET}`)}`,
          },
          params: {
            per_page: 12,
            orderby: 'date',
            order: 'desc',
            _fields: 'id,slug,name,images,meta_data', // Request only necessary fields
          },
        });

        const fetchedProducts = produtosResponse.data;

        setProdutos(fetchedProducts);
        setProdutosFiltrados(fetchedProducts);
        localStorage.setItem(CACHE_KEY, JSON.stringify({
          products: fetchedProducts,
          timestamp: Date.now(),
        }));
      } catch (err) {
        console.error('Error fetching products:', err);
        setError('Unable to load products. Please try again later.');
      } finally {
        setLoading(false);  // Stop the loading state when data fetching is done
      }
    };

    fetchData();
  }, [baseUrl, setLoading]);

  const aplicarFiltros = (filtros) => {
    let filtrados = [...produtos];

    if (filtros.marca) {
      filtrados = filtrados.filter(produto => produto.meta_data.some(meta => meta.key === 'marca' && meta.value === filtros.marca));
    }

    if (filtros.precoMin || filtros.precoMax) {
      filtrados = filtrados.filter(produto => {
        const precoMeta = produto.meta_data.find(meta => meta.key === 'preco');
        const preco = precoMeta ? parseFloat(precoMeta.value) : 0;
        return (
          (!filtros.precoMin || preco >= filtros.precoMin) &&
          (!filtros.precoMax || preco <= filtros.precoMax)
        );
      });
    }

    if (filtros.anoMin || filtros.anoMax) {
      filtrados = filtrados.filter(produto => {
        const anoMeta = produto.meta_data.find(meta => meta.key === 'ano');
        const ano = anoMeta ? parseInt(anoMeta.value, 10) : 0;
        return (
          (!filtros.anoMin || ano >= filtros.anoMin) &&
          (!filtros.anoMax || ano <= filtros.anoMax)
        );
      });
    }

    if (filtros.combustivel) {
      filtrados = filtrados.filter(produto => produto.meta_data.some(meta => meta.key === 'combustivel' && meta.value === filtros.combustivel));
    }

    if (filtros.cor) {
      filtrados = filtrados.filter(produto => produto.meta_data.some(meta => meta.key === 'cor' && meta.value === filtros.cor));
    }

    if (filtros.quilometrosMin || filtros.quilometrosMax) {
      filtrados = filtrados.filter(produto => {
        const quilometrosMeta = produto.meta_data.find(meta => meta.key === 'quilometros');
        const quilometros = quilometrosMeta ? parseInt(quilometrosMeta.value, 10) : 0;
        return (
          (!filtros.quilometrosMin || quilometros >= filtros.quilometrosMin) &&
          (!filtros.quilometrosMax || quilometros <= filtros.quilometrosMax)
        );
      });
    }

    if (filtros.potencia || filtros.potenciaMin) {
      filtrados = filtrados.filter(produto => {
        const potenciaMeta = produto.meta_data.find(meta => meta.key === 'potencia');
        const potencia = potenciaMeta ? parseInt(potenciaMeta.value, 10) : 0;
        return (
          (!filtros.potencia || potencia >= filtros.potencia) &&
          (!filtros.potenciaMin || potencia >= filtros.potenciaMin)
        );
      });
    }

    if (filtros.tipo_de_caixa) {
      filtrados = filtrados.filter(produto => produto.meta_data.some(meta => meta.key === 'tipo_de_caixa' && meta.value === filtros.tipo_de_caixa));
    }

    if (filtros.tracao) {
      filtrados = filtrados.filter(produto => produto.meta_data.some(meta => meta.key === 'tracao' && meta.value === filtros.tracao));
    }

    if (filtros.numPortas) {
      filtrados = filtrados.filter(produto => produto.meta_data.some(meta => meta.key === 'numero_de_portas' && meta.value == filtros.numPortas));
    }

    if (filtros.segmento) {
      filtrados = filtrados.filter(produto => produto.meta_data.some(meta => meta.key === 'segmento' && meta.value === filtros.segmento));
    }

    setProdutosFiltrados(filtrados);
  };

  return (
    <>
      <Menu />
      <PageTitle title="Automóveis" image={CoverImage} text="Encontre o carro perfeito para si ou para a sua empresa." />
      <div className="produtos-section gridrowfull">
        {error && <p className="error-message">{error}</p>}
        <FiltroProdutos produtos={produtos} aplicarFiltros={aplicarFiltros} />
        <ListaProdutos produtos={produtosFiltrados} loading={false} error={error} />
      </div>
      <ContactoProduto className={'produtos-contacto-section'} />
      <Footer />
      <ToTop />
    </>
  );
}

export default Produtos;