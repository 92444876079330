import React from "react";
import { Link } from "react-router-dom";
import Button from "../button/Button";
import CallIcon from "../../assets/call.png"

// Formatter for numbers with thousand separators
const numberFormatter = new Intl.NumberFormat('pt-PT', {
  style: 'decimal',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const InfoAnuncio = ({ nome, ano, quilometros, combustivel, potencia, num_portas, preco }) => {
    return (
        <div className="info-anuncio-container col-12 col-t-12 col-d-4">
            <div className="info-anuncio-nome col-12">
                <h1>{nome}</h1>
            </div>
            <div className="info-anuncio-breve col-12">
                <span>Ano: {ano}</span>
                <span>Km's: {numberFormatter.format(quilometros)}</span>
                <span>Combustível: {combustivel}</span>
                <span>Cavalos: {potencia} cv</span>
                <span>Portas: {num_portas}</span>
            </div>
            <div className="info-anuncio-preco col-12">
                <span>{numberFormatter.format(preco)}€</span>
            </div>
            <div className="ver-mais-caracteristicas col-12">
                <a href="#caracteristicas">Ver mais caracteristicas</a>
            </div>
            <div className="contactar-button col-12">
                <a href="tel:9111111111">
                    <Button className="contactar" name={'Ligar Agora'} />
                </a>
                <a className="contactar-email" href="#contactar">
                    <Button className="contactar" name={'Contactar Email'} />
                </a>
            </div>
        </div>
    );
};

export default InfoAnuncio;