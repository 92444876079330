import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import QuemSomos from './pages/QuemSomos';
import Contactos from './pages/Contactos';
import Produtos from './pages/Produtos';
import PoliticaPrivacidade from './pages/PoliticaPrivacidade';
import ProdutoIndividual from './pages/ProdutoIndividual';
import LoadingScreen from './components/loadingScreen/LoadingScreen';
import './style.css';
import Servicos from './pages/Servicos';

function App() {
  const [loading, setLoading] = useState(false); // Initially false

  return (
    <Router>
      <>
        {loading && <LoadingScreen />} {/* Show loading screen only when loading is true */}
        <Routes>
          <Route path="/" element={<Home setLoading={setLoading} />} />
          <Route path="/quem-somos" element={<QuemSomos />} />
          <Route path="/servicos" element={<Servicos />} />
          <Route path="/contactos" element={<Contactos />} />
          <Route path="/carros" element={<Produtos setLoading={setLoading} />} /> {/* Pass setLoading to pages that need it */}
          <Route path="/carros/:marca/:produtoId" element={<ProdutoIndividual setLoading={setLoading} />} />
          <Route path="/politica-privacidade" element={<PoliticaPrivacidade />} />
        </Routes>
      </>
    </Router>
  );
}

export default App;