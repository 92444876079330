import React, { useEffect, useState, useMemo } from "react";
import axios from 'axios';
import { Link } from 'react-router-dom';

// Formatter for numbers with thousand separators
const numberFormatter = new Intl.NumberFormat('pt-PT', {
  style: 'decimal',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const UltimasEntradas = ({ setLoading }) => { // Accept setLoading as a prop
    const [produtos, setProdutos] = useState([]);
    const [error, setError] = useState(null);

    const baseUrl = process.env.REACT_APP_API_URL;
    const consumerKey = process.env.REACT_APP_WC_CONSUMER_KEY;
    const consumerSecret = process.env.REACT_APP_WC_CONSUMER_SECRET;

    const CACHE_KEY = 'ultimasEntradasCache';
    const CACHE_VALIDITY_PERIOD = 60 * 60 * 1000; // 1 hour

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true); // Start loading
            const cachedData = JSON.parse(localStorage.getItem(CACHE_KEY));

            if (cachedData && (Date.now() - cachedData.timestamp < CACHE_VALIDITY_PERIOD)) {
                setProdutos(cachedData.products);
                setLoading(false);
                return;
            }

            try {
                const produtosResponse = await axios.get(`${baseUrl}/products`, {
                    params: {
                        per_page: 4, // Limit the number of products
                        order: 'desc',
                        _fields: 'id,slug,name,images,meta_data', // Request only necessary fields
                        _embed: 'meta_data',
                        consumer_key: consumerKey,
                        consumer_secret: consumerSecret,
                    }
                });

                const fetchedProducts = produtosResponse.data;

                setProdutos(fetchedProducts);
                localStorage.setItem(CACHE_KEY, JSON.stringify({
                    products: fetchedProducts,
                    timestamp: Date.now()
                }));
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false); // End loading
            }
        };

        fetchData();
    }, [baseUrl, consumerKey, consumerSecret, setLoading]);

    const processProductData = (produto) => {
        const metaDataMap = produto.meta_data.reduce((acc, meta) => {
            acc[meta.key] = meta.value;
            return acc;
        }, {});

        return {
            id: produto.id,
            slug: produto.slug,
            name: produto.name,
            imageUrl: produto.images.length > 0 ? produto.images[0].src : '',
            year: metaDataMap['ano'] || 'N/A',
            kilometers: numberFormatter.format(metaDataMap['quilometros']) || 'N/A',
            fuel: metaDataMap['combustivel'] || 'N/A',
            power: numberFormatter.format(metaDataMap['potencia']) || 'N/A',
            price: numberFormatter.format(metaDataMap['preco']) || 'N/A',
            brand: metaDataMap['marca'] || 'unknown-brand',
        };
    };

    const processedProdutos = useMemo(() => {
        return produtos.map(processProductData);
    }, [produtos]);

    if (error) return <p>Error loading products: {error.message}</p>;

    return (
        <section className="produtos-destaque-section gridrow">
            <div className="produtos-destaque-titulo col-12">
                <h2>Últimas Entradas</h2>
            </div>
            <div className="produtos-destaque-lista col-12">
                {processedProdutos.length > 0 ? processedProdutos.map((produto) => (
                    <Link 
                        to={`/carros/${produto.brand}/${produto.id}`} 
                        key={produto.id} 
                        className="produtos-destaque-item"
                    >
                        <div className="produtos-destaque-item-imagem">
                            <img 
                                src={produto.imageUrl} 
                                alt={produto.name || 'Product image'} 
                                loading="lazy" // Lazy loading the images for better performance
                            />
                        </div>
                        <div className="produtos-destaque-item-conteudo">
                            <div className="produtos-destaque-item-nome">
                                <h3>{produto.name}</h3>
                            </div>
                            <div className="produtos-destaque-item-caracteristicas">
                                <span>{produto.year}</span>
                                <span>{produto.kilometers} km</span>
                                <span>{produto.fuel}</span>
                                <span>{produto.power} cv</span>
                                <span className="preco">{produto.price} €</span>
                            </div>
                        </div>
                    </Link>
                )) : (
                    <p>No products found.</p>
                )}
            </div>
        </section>
    );
};

export default UltimasEntradas;