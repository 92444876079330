import React from "react";
import { Link } from "react-router-dom";

// Helper function to get meta data by key
const getMetaValue = (metaData, key) => {
  const meta = metaData.find(meta => meta.key === key);
  return meta ? meta.value : '';
};

// Formatter for numbers with thousand separators
const numberFormatter = new Intl.NumberFormat('pt-PT', {
  style: 'decimal',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const ListaProdutos = ({ produtos, loading, error }) => {
  // Handle loading and error states
  if (loading) return <p>A carregar produtos em destaque...</p>;
  if (error) return <p>Erro ao carregar os dados: {error}</p>;

  return (
    <div className="lista-produtos-section col-12 col-t-12 col-d-10">
      <div className="lista-produtos-container">
        {produtos.length > 0 ? produtos.map((produto) => (
          <Link 
            to={`/carros/${getMetaValue(produto.meta_data, 'marca')}/${produto.id}`} 
            key={produto.id} 
            className="produtos-destaque-item"
          >
            <div className="produtos-destaque-item-imagem">
              {/* Display the first image */}
              {produto.images && produto.images.length > 0 && (
                <img 
                  src={produto.images[0].src} 
                  alt={produto.name || 'Imagem do produto'} 
                />
              )}
            </div>
            <div className="produtos-destaque-item-conteudo">
              <div className="produtos-destaque-item-nome">
                <h3>{produto.name}</h3>
              </div>
              <div className="produtos-destaque-item-caracteristicas">
                <span>{getMetaValue(produto.meta_data, 'ano')}</span>
                <span>{numberFormatter.format(getMetaValue(produto.meta_data, 'quilometros'))} km</span>
                <span>{getMetaValue(produto.meta_data, 'combustivel')}</span>
                <span>{numberFormatter.format(getMetaValue(produto.meta_data, 'potencia'))} cv</span>
                <span className="preco">{numberFormatter.format(getMetaValue(produto.meta_data, 'preco'))} €</span>
              </div>
            </div>
          </Link>
        )) : (
          <p className="aviso-texto">Nenhum produto encontrado com essas características.</p>
        )}
      </div>
    </div>
  );
}

export default ListaProdutos;