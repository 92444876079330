import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Menu from '../components/menu/Menu';
import ToTop from '../components/toTop/ToTop';
import Footer from '../components/footer/Footer';
import GaleriaProduto from "../components/galeriaProduto/GaleriaProduto";
import InfoAnuncio from '../components/infoAnuncio/InfoAnuncio';
import Breadcrumbs from "../components/breadcrumbs/Breadcrumbs";
import CaracteristicasProdutos from "../components/caracteristicasProdutos/CaracteristicasProdutos";
import DescricaoProduto from "../components/descricaoProduto/DescricaoProduto";
import SobreNos from "../components/sobreNos/SobreNos";
import ContactoProduto from "../components/contactoProduto/ContactoProduto";

const ProdutoIndividual = ({ setLoading }) => {
    const [produto, setProduto] = useState(null);
    const [error, setError] = useState(null);

    const { produtoId } = useParams();
    const baseUrl = process.env.REACT_APP_API_URL;
    const CACHE_KEY = `produto_${produtoId}_cache`;
    const CACHE_VALIDITY_PERIOD = 60 * 60 * 1000; // 1 hour

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true); // Start loading
            const cachedData = JSON.parse(localStorage.getItem(CACHE_KEY));

            if (cachedData && (Date.now() - cachedData.timestamp < CACHE_VALIDITY_PERIOD)) {
                setProduto(cachedData.product);
                setLoading(false);
                return;
            }

            try {
                const produtoResponse = await axios.get(`${baseUrl}/products/${produtoId}`, {
                    headers: {
                        Authorization: `Basic ${btoa(`${process.env.REACT_APP_WC_CONSUMER_KEY}:${process.env.REACT_APP_WC_CONSUMER_SECRET}`)}`,
                    },
                    params: {
                        _fields: 'id,name,images,meta_data' // Request only necessary fields
                    }
                });

                const fetchedProduct = produtoResponse.data;

                setProduto(fetchedProduct);
                localStorage.setItem(CACHE_KEY, JSON.stringify({
                    product: fetchedProduct,
                    timestamp: Date.now(),
                }));
            } catch (err) {
                console.error('Error fetching product details:', err);
                setError('Unable to load product details. Please try again later.');
            } finally {
                setLoading(false); // End loading
            }
        };

        fetchData();
    }, [produtoId, baseUrl, setLoading]);

    if (!produto && !error) return <p>Loading product details...</p>;
    if (error) return <p>Error loading product: {error}</p>;
    if (!produto) return <p>Product not found.</p>;

    const imageGallery = produto.images || [];

    // Extract and format fields from meta_data
    const metaData = produto.meta_data.reduce((acc, meta) => {
        acc[meta.key] = meta.value;
        return acc;
    }, {});

    // Use the exact keys for ACF fields
    const {
        nome = 'Product',
        ano = 'N/A',
        quilometros = 'N/A',
        combustivel = 'N/A',
        potencia = 'N/A',
        numero_de_portas: numPortas = 'N/A',
        preco = 'N/A',
        descricao = ''
    } = metaData;

    return (
        <>
            <Menu />
            <Breadcrumbs />
            <section className="gridrow">
                <GaleriaProduto 
                    imageGallery={imageGallery}
                    name={nome} // Use the ACF field key here
                />
                <InfoAnuncio
                    nome={nome} // Use the ACF field key here
                    ano={ano}
                    quilometros={quilometros}
                    combustivel={combustivel}
                    potencia={potencia}
                    numPortas={numPortas}
                    preco={preco}
                />
            </section>
            <CaracteristicasProdutos produto={produto} />
            <DescricaoProduto descricao={descricao} />
            <SobreNos />
            <ContactoProduto />
            <Footer />
            <ToTop />
        </>
    );
};

export default ProdutoIndividual;