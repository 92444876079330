import '../style.css';
import Menu from '../components/menu/Menu';
import ToTop from '../components/toTop/ToTop';
import PageTitle from '../components/pageTitle/PageTitle';
import CoverImage from '../assets/cover02.jpg'
import InfoContactos from '../components/infoContactos/InfoContactos';
import ContactoProduto from '../components/contactoProduto/ContactoProduto';
import Ligue from '../components/ligue/Ligue';
import Footer from '../components/footer/Footer';

function Contactos() {
  return (
    <>
        <Menu />
        <PageTitle title="Contactos" image={CoverImage} text="Estamos aqui para ajudar. Fale connosco hoje." />
        <InfoContactos />
        <Ligue />
        <ContactoProduto />
        <ToTop />
        <Footer />
    </>
  );
}

export default Contactos;