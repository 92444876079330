import React from "react";

const ContactoProduto = ({className}) => {
    return (
        <section id="contactar" className={`contacto-produto-section gridrowfull ${className}`}>
            <div className="contacto-produto-container col-12 col-t-12 col-d-7">
                <h2 className="col-12 col-t-12 col-d-8">Entre em Contacto Connosco</h2>
                <p className="col-12 col-t-12 col-d-8">Interessado neste produto? Preencha o formulário abaixo e entraremos em contacto consigo o mais breve possível!</p>
                <form className="contacto-produto-form col-12">
                    <div className="form-group">
                        <label htmlFor="nome">Nome</label>
                        <input type="text" id="nome" name="nome" placeholder="O seu nome" required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" name="email" placeholder="O seu email" required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="telefone">Telefone</label>
                        <input type="tel" id="telefone" name="telefone" placeholder="O seu telefone" required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="mensagem">Mensagem</label>
                        <textarea id="mensagem" name="mensagem" placeholder="Escreva a sua mensagem" rows="5" required></textarea>
                    </div>
                    <button type="submit" className="contacto-produto-submit">Enviar Mensagem</button>
                </form>
            </div>
        </section>
    );
}

export default ContactoProduto;