import React, { useState } from 'react';

const GaleriaProduto = ({ imageGallery = [], name = 'Produto' }) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const handlePrevImage = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex === 0 ? imageGallery.length - 1 : prevIndex - 1
        );
    };

    const handleNextImage = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex === imageGallery.length - 1 ? 0 : prevIndex + 1
        );
    };

    if (imageGallery.length === 0) return <p>Sem imagens disponíveis.</p>;

    return (
        <div className="galeria-produto-container col-12 col-t-12 col-d-8">
            <div className="galeria-produto-imagens col-12">
                <div
                    className="imagem-principal"
                    style={{ transform: `translateX(-${currentImageIndex * 100}%)` }}
                >
                    {imageGallery.map((image, index) => (
                        <img
                            key={index}
                            src={image.src}  // Directly use the src from API
                            alt={name}
                            className="slide-image"
                        />
                    ))}
                </div>
                <div className="navegacao-imagens">
                    <button onClick={handlePrevImage}>&#60;</button>
                    <button onClick={handleNextImage}>&#62;</button>
                </div>
            </div>
            <div className="miniaturas-imagens col-12">
                {imageGallery.map((image, index) => (
                    <img
                        key={index}
                        src={image.src}  // Directly use the src from API
                        alt={`Miniatura ${index}`}
                        className={index === currentImageIndex ? "ativa" : ""}
                        onClick={() => setCurrentImageIndex(index)}
                        style={{ cursor: 'pointer' }}
                    />
                ))}
            </div>
        </div>
    );
};

export default GaleriaProduto;